<template>
  <div>
    <el-input type="text" ref="nameinput" v-model="blockValue" maxlength="60" readonly>
      <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="opendialog"/>
    </el-input>
    <zj-blockquery :calcform="searchForm" :show.sync="showBlockQuery" :products="searchProducts" @selectRate="onSelectRate"/>
  </div>
</template>

<script>
import ZjBlockquery from "@/views/order/common/ZjBlockquery";

export default {
  name: "ZjBlockselect",
  components:{ZjBlockquery},
  props:{
    calcform:{ //初始化计算的对象  外层要用这个对象
      type:Object,
      default:function(){
        return {
          block:'',
          products:[],
          arrdate:'2020-01-08',
          deptdate:'2020-01-10',
        }
      }
    },
    products:Array,
    value: {
      type: [String, Number],
      required: true
    }
  },
  data(){
    return {
      searchForm:this.calcform,
      showDialog:false,
      showBlockQuery:false,
      blockValue:this.value,
      searchProducts:this.products
    }
  },
  watch:{
    value(newVal) {
      this.blockValue = newVal
    },
    //监听myValue，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
    //参考官网：https://cn.vuejs.org/v2/guide/components-custom-events.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E7%BB%84%E4%BB%B6%E7%9A%84-v-model
    blockValue(newVal) {
      // this.$emit('input', newVal)
      this.$emit("input", this.blockValue);
    },
    calcform(){
      this.searchForm=this.calcform;
    },
    products(){
      this.searchProducts=this.products;
    }
  },
  methods:{
    opendialog:function(){
      // if(this.showRateQuery==undefined){
      //     this.showRateQuery=true
      // }
      // this.searchForm.product=this.calcform.rmtype;
      this.showBlockQuery=true
    },
    onSelectRate(selects){
      if(selects.block!=null){
        this.blockValue=selects.block;  //选择的内容更新到文本区域
        this.$emit('selectblock',selects);
      }
    },
    clean(){
      this.blockValue='';
    }
  }
}
</script>

<style scoped>

</style>
