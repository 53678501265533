<template>
  <div>
    <el-main>
      <el-form ref="telform" :inline="true" :model="telform" label-width="60px">
        <el-form-item label="来电">
          <el-input ref="telinput" v-model="telform.phone" @keyup.enter.native="loadData(1)"/>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="telform.guestname" @keyup.enter.native="loadData(1)"/>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input v-model="telform.orderno" @keyup.enter.native="loadData(1)"/>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="telform.showcancel" @change="loadData(1)">显示取消</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loadData(1)">订单查询</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" :data="colorderlist" border max-height="150px" :show-overflow-tooltip="true" stripe>
        <el-table-column prop="status" label="状态" min-width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status==='C'? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="subres" label="子预定" min-width="100">
          <template slot-scope="subresscope">
            <el-badge v-if="subresscope.row.hasroom" value="房" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasticket" value="票" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hascatering" value="餐" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasmeeting" value="会" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasspa" value="娱" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasboat" value="船" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasguide" value="导" class="item" type="primary"/>
            <el-badge v-if="subresscope.row.hasentertainment" value="自" class="item" type="primary"/>
          </template>
        </el-table-column>
        <el-table-column prop="roomprice" label="总价" min-width="75" :formatter="totalFormatter"/>
        <el-table-column prop="deposit" label="付款" min-width="75"/>
        <el-table-column prop="name" label="姓名" :show-overflow-tooltip="true" min-width="120"/>
        <el-table-column prop="telephone" label="电话" min-width="120"/>
        <el-table-column prop="colno" label="订单号" min-width="150"/>
        <el-table-column prop="arrdate" label="到店" min-width="100" />
        <el-table-column prop="enddate" label="离店" min-width="100" />
        <el-table-column fixed="right" prop="operation" label="操作" min-width="150">
          <template slot-scope="operatescope">
            <el-button size="mini" type="primary" @click="loadColrs(operatescope.row.colno)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-divider content-position="left"><strong>预订信息</strong></el-divider>

      <el-form ref="orderform" :model="orderform" :rules="rules" label-width="60px">
        <el-row :gutter="24">
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="姓名" prop="guestname">
              <zj-profileselect v-model="orderform.guestname" :no.sync="orderform.profileid" :ptype="''" @selectprofile="updProfileIno"/>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="电话" prop="telno">
              <el-input v-model="orderform.telno" />
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="人数" prop="person">
              <el-input-number v-model="orderform.person" :min="1" :max="99" />
            </el-form-item>
          </el-col>
          <el-col :md="9" :sm="12" :xs="24">
            <el-form-item label="到店">
              <el-date-picker v-model="dateselect" type="daterange" align="right" unlink-panels
                              range-separator="至" start-placeholder="开始日期" end-placeholder="离店日期"
                              :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="渠道" prop="channel">
              <el-select v-model="orderform.channel" placeholder="请选择渠道" filterable>
                <el-option v-for="item in channels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="类型" prop="restype">
              <el-select v-model="orderform.restype" placeholder="预订类型" clearable filterable >
                <el-option v-for="item in restypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="市场">
              <el-select v-model="orderform.market" placeholder="请选择市场" filterable>
                <el-option v-for="item in markets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="客源">
              <el-select v-model="orderform.scode" placeholder="请选择客源" filterable>
                <el-option v-for="item in scodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="24" :sm="24" :xs="24">
            <el-form-item label="备注">
              <el-input v-model="orderform.remark" type="textarea" :rows="2" placeholder=""/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-button type="primary" @click="opResource('R')">客房资源</el-button>
      <el-button type="primary" @click="opCater">餐饮资源</el-button>
      <!-- <el-button type="primary" @click="opResource('T')">门票资源</el-button> -->
      <el-button type="primary" @click="opTicket">门票资源</el-button>
      <el-button type="primary" @click="opMeeting">会议资源</el-button>
      <el-button type="primary" @click="openKits">套餐预订</el-button>
      <span style="float: right">金额:<span class="pricelabel" >{{ totalprice }}</span></span>

      <el-divider content-position="left">
        <strong>预订资源
          <span v-if="kitcode!==''">套餐:{{ kitdesc }}</span>
          <span v-if="kitcode!==''">数量:{{ kitnum }}</span></strong>
      </el-divider>

      <el-table :data="itemlist" border>
        <el-table-column prop="producttype" label="项目" :formatter="productFormatter"/>
        <el-table-column prop="productdesc" label="产品" />
        <el-table-column prop="arrdate" label="开始日期" />
        <el-table-column prop="deptdate" label="结束日期" />
        <el-table-column prop="block" label="block锁房" />
        <el-table-column prop="price" label="价格" />
        <el-table-column prop="num" label="数量" />
        <el-table-column prop="op" label="操作" width="200">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" icon="el-icon-minus" @click="updateitem(scope.row,scope.$index,-1)" />
              <el-button type="primary" icon="el-icon-plus" @click="updateitem(scope.row,scope.$index,1)"/>
              <el-button type="danger" icon="el-icon-delete" @click="removeItem(scope.row,scope.$index)" />
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>

      <el-divider content-position="left"/>
      <el-button type="primary" :loading="buttonLoading" :disabled="itemlist.length<=0" @click="saveProducts">确认预订</el-button>
      <el-button type="danger" :loading="buttonLoading" :disabled="orderid.length<=0" @click="cancelProducts">取消预订</el-button>
      <el-button type="primary" @click="reset">清空内容</el-button>

    </el-main>

    <zj-ratequery :type="resourceform.type" :arrdate="orderform.arrdate" :deptdate="orderform.deptdate"
                  :person="orderform.person" :show.sync="showResource" :calcform="orderform" @selectRate="onSelectRate"/>
    <kit-list :show.sync="showKitResource" :calcform="orderform" :arrdate="orderform.arrdate" :deptdate="orderform.deptdate" @refresh="onCloseKit"/>
    <cti-cater v-if="showCaterResource" :show.sync="showCaterResource" :calcform="orderform" :cti="true" :arrdate="orderform.arrdate" @selected="onSelectRate"/>
    <cti-ticket v-if="showTicketResource" :show.sync="showTicketResource" :arrdate="orderform.arrdate" :channel="orderform.channel" :cti="true"
                @selected="onSelectRate"/>
    <el-dialog v-dialogDrag title="会议资源" :visible.sync="showMeetingResource" :close-on-click-modal="false" width="70%" top="6vh" append-to-body>
      <MeetingResource :visible.sync="showMeetingResource" :startdate="orderform.arrdate" :enddate="orderform.deptdate" :need-open-meeting-detail="false" :itemlist="itemlist" @selected="onSelectRate"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag :visible.sync="showColDetailDialog" :close-on-click-modal="false" width="80%" top="6vh"
               @closed="closeColDetailDialog">
      <col-detail ref="coldetail" :colno="opencolid" :visible.sync="showColDetailDialog"/>
    </el-dialog>

  </div>

</template>
<script>
// import {formatDate} from '@/utils/calcdate';
import {batchorder,calcbatchprice,loadCtiData} from "@/api/order/order";
import {getColStatus} from '@/utils/status-format';
import {cancel_col} from "@/api/order/col"
import {select_data} from '@/api/common/custom-data';
import {select_res} from "@/utils/select_data_res";
import {getProductDesc} from '@/utils/status-format';
import ZjProfileselect from "@/views/order/common/ZjProfileselect";
import ZjRatequery from "@/views/order/common/ZjRatequery";
import KitList from '@/views/order/kit/kitlist';
import CtiCater from '@/views/order/cti/cti-cater';
import  CtiTicket from '@/views/order/cti/cti-ticket';
import {formatDate, reckonDay} from '@/utils/calcdate';
import ColDetail from '@/views/order/col/coldetail';
import MeetingResource from '@/views/order/meeting/meetingresource';

export default {
  name: "CtiMain",
  components: {ColDetail, CtiCater,CtiTicket,KitList, ZjProfileselect, ZjRatequery, MeetingResource},
  props:{
    telno:{ type: String, default:""}  //CTI 电话接入时.会传入一个电话号码
  },
  data() {
    return {
      telform:{
        phone:this.telno, guestname:'', orderno:'', showcancel:false, mode:1
      },
      orderid: '',  //保存之后生成的综合预订号
      opencolid: '',
      showColDetailDialog: false,
      // telephone:this.telno,
      totalprice:0.00,
      openType:'R',
      kitcode:'', //选择的套餐代码
      kitdesc:'',//套餐描述
      kitnum:'',//套餐数量
      orderform:{
        guestname:'', telno:this.telno, profileid:'', channel:'', market:'', scode:'', booker:'',
        restype:'', companyid:'', companyname:'', travelid:'', travelname:'', groupid:'', groupname:'',
        remark:'', person:1, arrdate:formatDate(new Date()), deptdate:formatDate(reckonDay(new Date(),1)),
        packagecode:'', packagenum:'', block:''
      },
      resourceform:{},
      // orderlist:[],
      // profilelist:[],
      colorderlist:[],
      itemlist:[],
      rules: {
        guestname: [{required: true, message: '查询档案或填入姓名', trigger: 'blur'}],
        telno: [{required: true, message: '请填入电话或手机号码', trigger: 'blur'}]
        // block: [{ required: true, message: '请选择锁房', trigger: 'change' }],
      },
      showResource:false,  //打开客房.门票资源查询
      showKitResource:false,//打开套餐
      showCaterResource:false,//打开餐饮资源查询
      showTicketResource:false,//打开门票资源查询
      showMeetingResource:false,//打开会议资源查询
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
      },
      //下拉数据:
      markets:[],
      scodes:[],
      channels:[],
      restypes:[],
      loading:false, buttonLoading:false
    }
  },
  computed:{
    dateselect:{
      get:function(){
        return this.orderform.arrdate!=null && this.orderform.deptdate!=null?[this.orderform.arrdate,this.orderform.deptdate]:[];
      },
      set:function(dateValue){
        this.orderform.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.orderform.deptdate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    }
  },
  watch:{
    telno: function(val){
      this.telform.phone = this.telno = val;
    }
    // clear: function (val) {
    //   if(val==true){
    //     this.reset();
    //   }
    // },
  },
  mounted: function () {
    this.$refs.telinput.focus();
    this.initDialog();
    this.loadData(0);
    if(this.telno!==''){     //prop 传参.CTI 来电的话
      this.$store.dispatch('app/toggleSideBar');  //隐藏左侧菜单.获取更大的可视菜单
    }
  },
  methods:{
    totalFormatter(row){
      return (row.roomprice+row.ticketprice+
              row.cateringprice+row.meetingprice+row.enterprice+
              row.spaprice+row.boatprice+row.guideprice).toFixed(2);
    },
    statusFormatter(val){
      return getColStatus(val);
    },
    initDialog(){
      select_data({"keyname": "subchannel,market,source,restype"}).then(response => {
        this.channels = select_res(response.data,"subchannel");
        this.markets = select_res(response.data,"market");
        this.scodes = select_res(response.data,"source");
        this.restypes=select_res(response.data,"restype");
      })
    },
    reset(){ //批量将表单内所有值设置为空.
      // this.telform = {phone:this.telno, guestname:'', orderno:'', showcancel:false, mode:1};
      this.itemlist=[];
      // this.orderlist=[];
      // this.profilelist=[];
      this.kitcode=this.kitdesc='';
      this.kitnum=0;
      this.totalprice=0;
      this.orderform.guestname='';
      this.orderform.profileid='';
      this.orderform.remark='';
      this.orderform.telno='';
    },
    productFormatter(row){
      return getProductDesc(row.producttype);
    },
    removeItem(row,index) {
      this.$confirm("是否移除?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        this.itemlist.splice(index,1);
        this.calcProductPrice();
      });
    },
    updateitem(row,index,val){
      if(row.producttype==='R'||row.producttype==='T'){
        row.num=Math.max(row.num+val,1);
        this.calcProductPrice();
      }
    },
    updProfileIno(selectValue){
      this.orderform.guestname=selectValue.name;
      this.orderform.profileid=selectValue.profileid;
      this.orderform.telno=selectValue.mobile;//selectValue.mobile;
      this.$refs.orderform.validate();
    },
    loadColrs(colno){
      this.opencolid = colno;
      this.showColDetailDialog = true;
    },
    loadData(parm_mode){
      this.telform.mode = parm_mode;
      this.loading = true;
      loadCtiData(this.telform).then(response => {
        this.colorderlist = response.data.records;
        if(parm_mode===0){
          this.orderform.market=response.data.defmarket;
          this.orderform.scode=response.data.defscode;
          this.orderform.channel=response.data.defchannel;
          this.orderform.restype=response.data.defrestype;
          this.orderform.profileid=response.data.profileid;
          this.orderform.guestname=response.data.guestname;
        }
      }).finally(() => { this.loading = false;})
    },
    opResource(producttype){
      this.resourceform={...this.orderform};
      this.resourceform.productgroup='';
      this.resourceform.type=producttype;
      this.openType=producttype;
      this.showResource=true;
    },
    opCater(){
      this.showCaterResource=true;
    },
    opTicket() {
      this.showTicketResource = true;
    },
    opMeeting() {
      this.showMeetingResource = true;
    },
    openKits() {
      this.showKitResource = true;
    },
    onSelectRate(selectValue,kitinfo){
      if(kitinfo !=undefined){
        this.kitcode=kitinfo.kitcode;
        this.kitdesc=kitinfo.kitdesc;
        this.kitnum=kitinfo.kitnum;
        this.orderform.packagecode=kitinfo.kitcode;
        this.orderform.packagenum=kitinfo.kitnum;
        this.itemlist=[];  //每次添加套餐之后.都先清空本地的列表.因为每次只能新建一个套餐.
      }
      if(Array.isArray(selectValue)){
        this.itemlist=this.itemlist.concat(selectValue);//套餐窗口返回的是一堆数组
      }else{
        if(!selectValue.hasOwnProperty('num')){
          selectValue.num=1;
        }
        selectValue['num']=1;
        this.itemlist.push(selectValue); //ratequery 返回的是单个对象
      }
      setTimeout(this.calcProductPrice(),100);
    },
    calcProductPrice(){
      let  form={...this.orderform};
      form.items=this.itemlist;
      let _this=this;
      calcbatchprice(form).then(response => {
        _this.totalprice = response.data.totalamount;//.toFixed(2);
        this.totalprice=response.data.totalamount;
      });
    },
    closeColDetailDialog(){
      this.loadData(1);
    },
    saveProducts(){
      let  form={...this.orderform};
      form.items=this.itemlist;
      form.packagecode=this.kitcode;
      form.packagenum=this.kitnum;
      form.totalAmount = this.totalprice;
      this.$refs['orderform'].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          batchorder(form).then(response => {
            this.orderid=response.data.orderid;
            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+this.orderid, confirmButtonText: '关闭'}).catch(() => {});
            this.loadData(1);
            this.reset();  //重置当前页面
          }).finally(()=>{
            this.buttonLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    cancelProducts(){
      this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
        this.buttonLoading = true;
        cancel_col({"colno":this.orderid}).then(() => {
          this.orderid='';
          this.$message({ message: '取消成功!', type: 'success'});
        }).finally(()=>{
          this.buttonLoading = false;
        });
      }).catch(() => {});
    },
    onCloseKit(){
      this.showKitResource = false;
      this.loadData(1);
    }
  }
}
</script>

<style scoped>
.pricelabel{
  margin-top: 10px;
  color:#c11a25;
  font-size: 22px;
  font-weight: bolder;
}
/*.normallabel{*/
/*  color: #000000;*/
/*  font-size: 22px;*/
/*  font-weight: bolder;*/
/*}*/

</style>
