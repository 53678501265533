<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="15">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="客人姓名">
                <el-input v-model="form.name" maxlength="60" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码">
                <el-input v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="支付渠道">
                <el-select v-model="form.type" placeholder="请选择支付渠道">
                  <el-option label="系统录入" value="S"/>
                  <el-option label="网上支付" value="W"/>
                  <el-option label="线下支付" value="O"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款人" prop="payname">
                <el-input v-model="form.payname" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款日期" prop="paydate">
                <el-date-picker v-model="form.paydate" align="right" type="date" placeholder="选择付款日期"
                                :picker-options="pickerOptions" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" clearable filterable>
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款金额" prop="hbamount">
                <el-tooltip class="item" effect="dark" :content="'应付金额:'+form.amount" placement="top-start">
                  <el-input ref="amountinput" v-model="form.hbamount" maxlength="12" :readonly="form.sqlid>0"
                            oninput="value=value.match(/^\d*(\.?\d{0,2})/g)"/>
                </el-tooltip>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="应付金额">
                <el-input v-model="form.amount" readonly/>
              </el-form-item>
            </el-col>
            -->
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="退款金额">
                <el-input v-model="form.refund" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="扣款金额">
                <el-input v-model="form.debit" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式" prop="payment">
                <el-select v-model="form.payment" placeholder="请选择付款方式" clearable filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="流水号">
                <el-input v-model="form.serialno"/>
              </el-form-item>
            </el-col>
          </el-row>
          <!--
          <el-row :gutter="20">
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="综合订单号">
                <el-input v-model="form.colno" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createinfo" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="退款信息">
                <el-input v-model="refundinfo" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          -->
          <el-row :gutter="15">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :disabled="form.sqlid>0||history"
                   :loading="buttonLoading" @click="handleSave">保存</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.status=='R'||history"
                   :loading="buttonLoading" @click="showRefundDialog=true">退款</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.status!='R'||history"
                   :loading="buttonLoading" @click="handleCancelRefund">撤销退款</el-button>
        <el-button v-if="userid==='admin'" type="danger" icon="el-icon-warning-outline"
                   :loading="buttonLoading" @click="handleFixDoublePost">修复重复入账</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="退款详情" :visible.sync="showRefundDialog" :close-on-click-modal="false" width="30%" append-to-body @open="getRefundValue">
      <span>
        <p>请确认以下信息:</p>
        <p>实付金额: {{ refundData.pay }}</p>
        <p v-if="!refundData.bytime">提前{{ refundData.days }}天,扣款{{ refundData.percent }}%</p>
        <p v-else>提前{{ refundData.hour }}小时,扣款{{ refundData.percent }}%</p>
        <p style="font-weight: bold;color: red">扣款金额: {{ refundData.debit }}</p>
        <p style="font-weight: bold;color: blue">退款金额: {{ refundData.refund }}</p>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-form ref="refundForm" :inline="true" :model="refundForm" :rules="refundrules" class="refund-form">
          <el-form-item>
            <el-switch v-model="refundForm.auto" active-text="扣款比例" inactive-text="系统比例"
                       @change="getRefundValue"/>
          </el-form-item>
          <el-form-item prop="percent">
            <el-input v-show="refundForm.auto" v-model.number="refundForm.percent" type="number" min="0" max="100"
                      @blur="getRefundValue"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleRefund">确定</el-button>
            <el-button @click="showRefundDialog=false">退出</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {load_pay, refund_pay, refund_value, save_pay, cancel_refund, fix_doublepost} from '@/api/order/pay';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  import {getPrepayStatus} from '@/utils/status-format';
  import '@/styles/pagecontainer.scss';

  export default {
    name:'PayDetail',
    props: {
      colno:{ type: String,default: ''},
      payid:{ type: Number,default: 0},
      history:{type: Boolean,default: false},
      visible:{type: Boolean,default: true},
      gotocol:{type: Boolean,default: false}
    },
    data() {
      return {
        userid: this.$store.getters.userid,
        form:{},
        refundData:{},
        refundForm:{
          auto:false,percent:0
        },
        pickerOptions: {
          // disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
        },
        rules: {
          payname: [{ required: true, message: '请输入付款人姓名', trigger: 'change' }],
          payment: [{ required: true, message: '请选择付款方式', trigger: 'change' }],
          paydate: [{ required: true, message: '请选择付款日期', trigger: 'change' }],
          hbamount: [{ required: true, message: '请输入正确的付款金额', trigger: 'change' }]
        },
        refundrules:{
          percent:[{ required: true, message: '请输入正确的扣款比例 0-100', trigger: 'change' },
            { type: 'number', message: '请输入正确的扣款比例 0-100', trigger: 'change' },
            { pattern:/^(?:[1-9]?\d|100)$/, message: '请输入正确的扣款比例 0-100', trigger: 'change' }]
        },
        subchannels:[],payments:[],
        showRefundDialog: false, buttonLoading:false
      }
    },
    computed:{
      showTitle(){ return (this.form.sqlid!=null&&this.form.sqlid>0?'查看预付款  付款单号: '+this.form.payno:'新建预付款')+' 综合订单号: '+this.form.colno},
      showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getPrepayStatus(this.form.status):''},
      // createinfo(){ return this.form.created==null||this.form.created=='1900-01-01'?'':this.form.created+'  '+this.form.createtime+'  '+this.form.creator},
      // refundinfo(){ return this.form.modifydate==null||this.form.modifydate=='1900-01-01'?'':this.form.modifydate+'  '+this.form.modifytime+'  '+this.form.modify}
    },
    watch:{
      visible: function(val){
        if(val){
          this.initDialog();
        }
      }
    },
    mounted: function(){
      this.$refs.amountinput.focus();
      select_data({"keyname":"subchannel,payment"}).then(response => {
          this.subchannels = select_res(response.data,"subchannel");
          this.payments = select_res(response.data,"payment");
      });
      this.initDialog();
    },
    methods: {
      initDialog(){
        load_pay({"sqlid":this.payid,"colno":this.colno,"history":this.history}).then(response => {
          this.form = response.data;
        }).catch(() => {
          this.$emit('update:visible',false);
        });
      },
      getRefundValue(){
        refund_value({"sqlid":this.form.sqlid,"custom":this.refundForm.auto,"percent":this.refundForm.percent}).then(response => {
          this.refundData = response.data;
        });
      },
      handleSave(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.buttonLoading = true;
            save_pay(this.form).then(() => {
              this.$message({ message: '保存成功!', type: 'success'});
              this.$emit('refresh');
              this.$emit('update:visible',false);
            }).finally(()=>{
              this.buttonLoading = false;
            });
          } else {
            return false;
          }
        });
      },
      handleRefund(){
        this.$refs['refundForm'].validate((valid) => {
          if (valid) {
            this.buttonLoading = true;
            this.showRefundDialog = false;
            refund_pay({"sqlid":this.form.sqlid,"custom":this.refundForm.auto,"percent":this.refundForm.percent}).then(() => {
              this.$message({ message: '退款成功!', type: 'success'});
              this.$emit('refresh');
              this.$emit('update:visible',false);
            }).finally(()=>{
              this.buttonLoading = false;
            });
          } else {
            return false;
          }
        });
      },
      handleCancelRefund(){
        this.$confirm('确定撤销退款?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          cancel_refund({"sqlid":this.form.sqlid}).then(() => {
            this.$message({ message: '撤销成功!', type: 'success'});
            this.$emit('refresh');
            this.$emit('update:visible',false);
          }).finally(()=>{
            this.buttonLoading = false;
          });
        }).catch(() => {});
      },
      handleFixDoublePost(){
        let refund = this.form.status === 'A';
        let confirmText = refund?'入了2笔预付款,需要退其中一笔?':'入了2笔退款,需要恢复其中一笔?';
        this.$confirm(confirmText, '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          fix_doublepost({"sqlid":this.form.sqlid}).then(() => {
            this.$message({ message: refund?'重复入账修复成功!':'重复退款修复成功!', type: 'success'});
          }).finally(()=>{
            this.buttonLoading = false;
          });
        }).catch(() => {});
      },
      gotoCol(){
        this.$emit('update:paycolno',this.form.colno);
        this.$emit('gotoCol');
      }
    }
}
</script>

<style scoped>

</style>
