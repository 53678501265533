<template>
  <div class="availRoomsDiv">
    <el-form :inline="true" :model="searchForm" class="search-form">
      <el-form-item label="日期">
        <el-date-picker v-model="dateselect" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <!--
      <el-form-item label="酒店">
        <el-input v-model="searchForm.hotel" clearable/>
      </el-form-item>
      -->
      <el-form-item label="房型">
        <el-input ref="roomtypeinput" v-model="searchForm.roomType" @keyup.enter.native="refreshTable(true)">
          <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showRoomtypeDialog=true"/>
        </el-input>
      </el-form-item>
      <!--      <el-form-item label="显示占用">-->
      <!--        <el-switch v-model="searchForm.reserved" active-color="#13ce66" inactive-color="#ff4949" @change="refreshTable"/>-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-radio-group v-model="searchForm.type">
          <el-radio :label="1" @change="refreshTable(false)">可卖</el-radio>
          <el-radio :label="2" @change="refreshTable(false)">占用</el-radio>
          <el-radio :label="3" @change="refreshTable(false)">房价</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable(true)">查询</el-button>
        <el-button type="primary" icon="el-icon-download" @click="syncAvailRooms">同步</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tableData" max-height="525px" border>
      <el-table-column prop="roomType" label="房型代码" min-width="40" fixed/>
      <template v-for="(tableCol,index) in tableCols">
        <el-table-column :key="index" :label="tableCol.date" >
          <el-table-column :key="index" :label="String(tableCol.total)">
            <el-table-column :key="index" :label="tableCol.percent">
              <template slot-scope="scope">
                <el-button type="text" @click="selectRate(scope.row,index)">
                  {{ scope.row.list[index].value }}
                </el-button>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </template>
    </el-table>
    <el-pagination :current-page="searchForm.pages.currentpage" :page-size="searchForm.pages.pagesize"
                   layout="sizes,total, prev, pager, next, jumper" :total="searchForm.pages.total" style="margin-top: 10px;float: right;"
                   background @current-change="handleCurrentChange" @size-change="handleSizeChange"/>

    <el-dialog v-dialogDrag :visible.sync="showDetail" width="35%" append-to-body title="房态明细信息">
      <el-form :model="dialogData" label-width="80px">
        <el-row :gutter="10">
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="房型代码">
              <el-input v-model="dialogData.roomtype" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="日期">
              <el-input v-model="dialogData.date" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="物理房量">
              <el-input v-model="dialogData.roomTotal" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="可卖房">
              <el-input v-model="dialogData.available" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="预定占用">
              <el-input v-model="dialogData.booking" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="超预定">
              <el-input v-model="dialogData.overbook" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="预留占用">
              <el-input v-model="dialogData.reserved" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :sm="12" :xs="24">
            <el-form-item label="坏房">
              <el-input v-model="dialogData.bad" style="width: 80%" readonly/>
            </el-form-item>
          </el-col>
        </el-row>
        <!--        <el-row :gutter="10">-->
        <!--          <el-col :md="24" :sm="24" :xs="24">-->
        <!--            <el-form-item label="详情">-->
        <!--              <el-input v-model="dialogData.remark" type="textarea" :rows="4" readonly/>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDetail=false">退出</el-button>
      </div>
    </el-dialog>
    <!-- 房型选择控件 -->
    <el-dialog v-dialogDrag title="选择房型" :visible.sync="showRoomtypeDialog" :close-on-click-modal="false" width="50%" append-to-body @opened="openRoomtypeDialog">
      <el-form :inline="true" class="search-form">
        <el-form-item label="房型">
          <el-input v-model="searchRoomtype" clearable/>
        </el-form-item>
        <el-form-item label="酒店">
          <el-select v-model="searchHotel" filterable clearable>
            <el-option v-for="item in hotels" :key="item.code" :label="item.code" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" :data="roomtypes.filter(data => (!searchRoomtype || data.code.toLowerCase().startsWith(searchRoomtype.toLowerCase())) &&
                  (!searchHotel || data.group===searchHotel))" :row-key="getRowKeys"
                style="width: 100%" max-height="350" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" :reserve-selection="true"/>
        <el-table-column property="code" label="代码" width="150"/>
        <el-table-column property="desc" label="描述" width="200"/>
        <el-table-column property="group" label="组" width="150"/>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectCode">选择</el-button>
        <el-button @click="showRoomtypeDialog=false">退出</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
  import {formatDate, reckonDay} from '@/utils/calcdate';
  // import {sync_availrooms,room_status_tabel_data,room_status_tabel_header,room_status_detail} from '@/api/statistic/statistic';
  import {sync_availrooms,room_status_tabel,room_status_detail} from '@/api/statistic/statistic';
  // import {startLoading, hideLoading} from '@/utils/loading';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  export default {
    name: 'sum_availrooms',
    data(){
      return{
        searchForm:{
          start:formatDate(new Date()),end:formatDate(reckonDay(new Date(),6)),
          roomType:'',type:1, refreshHeader:false, //reserved:false,hotel:'',
          pages:{currentpage:1, pagesize: 10, sortname: "sqlid", sortorder: "ascending"}
        },
        pickerOptions: {
          disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
        },
        loading:false,tableCols:[],tableData:[],//tableHead:{},list:[],available:{},leased:{},value:'',actual:'',bad:'',roomTotal:'',booking:'',overbook:'',tableDataTotal:0,
        showDetail:false,dialogData:{},showRoomtypeDialog:false,
        roomtypes:[],hotels:[],searchRoomtype:'',searchHotel:'',multipleSelection:[]
      }
    },
    computed:{
      dateselect:{
        get:function(){
          return this.searchForm.start!=null && this.searchForm.end!=null?[this.searchForm.start,this.searchForm.end]:[];
        },
        set:function(dateValue){
          this.searchForm.start = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
          this.searchForm.end = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
        }
      }
    },
    mounted: function(){
      this.$refs.roomtypeinput.focus();
      select_data({"keyname":"roomtype,hotel"}).then(response => {
        this.roomtypes = select_res(response.data,'roomtype');
        this.hotels = select_res(response.data,'hotel');
      });
      this.searchForm.refreshHeader = true;
      this.loadTable();
    },
    methods:{
      getRowKeys(row){
        return row.code;
      },
      refreshTable(refreshHeader){
        this.searchForm.pages.currentpage = 1;
        this.searchForm.refreshHeader = refreshHeader;
        this.loadTable();
      },
      loadTable(){
        this.loading = true;
        // room_status_tabel_header(this.searchForm).then(response => {
        //   this.tableCols = response.data.tableHead.leased.list;
        // }).finally(() => { this.loading = false;});
        // room_status_tabel_data(this.searchForm).then(response => {
        //   this.tableData = response.data.tableData;
        //   this.searchForm.pages.total = response.data.tableDataTotal;
        // }).finally(() => { this.loading = false;})
        room_status_tabel(this.searchForm).then(response => {
          if(this.searchForm.refreshHeader){
            this.tableCols = response.data.tableHead;
          }
          this.tableData = response.data.tableData;
          this.searchForm.pages.total = response.data.tableDataTotal;
        }).finally(() => { this.loading = false;});
      },
      selectRate(row,index) {
        this.showDetail = true;
        room_status_detail({"date":row.list[index].date,"roomType":row.roomType}).then(response => {
          this.dialogData = response.data;
        }).finally(() => { })
      },
      openRoomtypeDialog(){
        this.searchRoomtype = '';
        this.searchHotel = '';
        if(this.roomtypes!=null && this.roomtypes.length>0){
          this.roomtypes.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row,this.searchForm.roomType.toUpperCase().indexOf(row.code)>=0);
          });
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      selectCode(){
        this.showRoomtypeDialog = false;
        let selectCode = '';
        this.multipleSelection.forEach(selectValue => {
          selectCode = selectCode.length<=0?selectValue.code:selectCode+","+selectValue.code;
        });
        this.searchForm.roomType = selectCode;
      },
      handleCurrentChange(val){
        this.searchForm.pages.currentpage = val;
        this.searchForm.refreshHeader = false;
        this.loadTable();
      },
      handleSizeChange(val){
        this.searchForm.pages.pagesize = val;
        this.refreshTable(false);
      },
      syncAvailRooms(){
        this.$confirm('确定同步数据?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          // startLoading('.availRoomsDiv','同步房量数据中,请稍等...');
          sync_availrooms(this.searchForm).then(() => {
            // this.refreshTable();
            this.$message('同步后台运行中...请稍后刷新表格');
          });/*.finally(() => {
            hideLoading();
          })*/
        }).catch(() => {});
      }
    }
  }
</script>

<style scoped>

</style>