<template>
  <div class="navbar">
    <hamburger
      v-if="device=='mobile'"
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="section-container">{{$section}}</div>

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <cti class="right-menu-item"/>
        <!-- 顶部的一些组件放这里 -->
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!--        <el-tooltip content="设置大小" effect="dark" placement="bottom">-->
        <!--          <size-select id="size-select" class="right-menu-item hover-effect" />-->
        <!--        </el-tooltip>-->
      </template>

      <!-- BI -->
      <div class="imgdiv right-menu-item hover-effect">
        <el-tooltip class="item" effect="light" placement="bottom">
          <div slot="content">
            <img v-if="qrcode" :src="qrcode" alt="qrcode" style="width: 200px; height: 200px;"/>
          </div>
          <img src="../../assets/bi.png" class="img-avatar" style="border-radius: 0">
        </el-tooltip>
      </div>
      <!-- 快捷键 -->
      <div class="imgdiv right-menu-item hover-effect">
        <el-tooltip class="item" effect="dark" placement="bottom-end">
          <div slot="content" class="keymap-container">
            <p style="margin-top: 0px; font-weight: bold; font-size: 17px">快捷键</p>
            <div v-for="(item,index) in keyMaps" :key="index" class="keymap-row">
              <div>{{ item.text }}</div>
              <div class="key-box">
                <div v-for="(key,idx) in item.keys" :key="idx">
                  <span v-if="idx">+</span>
                  <span class="key">{{ key }}</span>
                </div>
              </div>
            </div>
          </div>
          <img src="../../assets/keymap.png" class="img-avatar" style="border-radius: 0">
        </el-tooltip>
      </div>
      <!-- 消息 -->
      <div class="imgdiv right-menu-item hover-effect">
        <el-badge v-if="msgs>0" :value="msgs" class="item">
          <img src="../../assets/msg.png" class="img-avatar" @click="showMsgView=true">
        </el-badge>
        <img v-else src="../../assets/msg.png" class="img-avatar" @click="showMsgView=true">
      </div>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
          <img src="../../assets/sysuser.png?imageView2/1/w/60/h/60" class="user-avatar">
          <!-- <svg-icon icon-class="userhead" /> -->
          <!-- <i class="el-icon-caret-bottom" /> -->
          {{ userid }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <!--
          <router-link to="/profile/index">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <router-link to="/">
            <el-dropdown-item>回到首页</el-dropdown-item>
          </router-link>
          -->
          <el-dropdown-item>
            <span style="display:block;" @click="showPwd=true">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span style="display:block;" @click="logout">注销</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog v-dialogDrag :visible.sync="showPwd" title="密码设置" width="40%" :close-on-click-modal="false" append-to-body>
      <user-pwd ref="passwordDialog" :userid="userid" :lcheck="true" @success="showPwd=false"/>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savePass">保存</el-button>
        <el-button @click="showPwd=false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="消息" :visible.sync="showMsgView" :close-on-click-modal="false" width="80%" append-to-body>
      <el-table :data="msgData" style="width: 100%" max-height="300">
        <el-table-column prop="name" label="机构名称" min-width="150"/>
        <el-table-column prop="reqmsg" label="消息内容" min-width="200"/>
        <el-table-column prop="reqdate" label="发布时间" min-width="150"/>
        <!-- <el-table-column prop="requser" label="发布人" min-width="100"/> -->
        <el-table-column prop="resmsg" label="回复内容" min-width="200"/>
        <el-table-column prop="resdate" label="回复时间" min-width="100"/>
        <el-table-column prop="resuser" label="回复人" min-width="100"/>
        <el-table-column fixed="right" prop="operation" min-width="80">
          <template slot-scope="scope">
            <el-button v-if="!responser" size="mini" type="primary" :disabled="scope.row.resmsg.length>0" @click="handleApply(scope.row)">回复</el-button>
            <el-button v-else size="mini" type="primary" :disabled="scope.row.status=='F'" @click="handleFinish(scope.row)">完成</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="getTraceList">刷新</el-button>
        <el-button @click="showMsgView=false">退出</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// import SizeSelect from '@/components/SizeSelect'
// import Search from '@/components/HeaderSearch'
import Cti from '@/components/Cti/cti.vue'
import UserPwd from '@/views/confadv/users/user-pwd';
import { get_trace_list, apply_trace, finish_trace } from '@/api/common/trace';

export default {
  components: {
    Breadcrumb,
    Hamburger,
    // SizeSelect,
    // Search,
    Cti,
    UserPwd
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device'])
  },
  data(){
    return {
      // userid: localStorage.getItem("userid")!=null?localStorage.getItem("userid"):'',
      userid: this.$store.getters.userid, username: this.$store.getters.name,
      showPwd: false, msgs: 0, msgData:[],showMsgView:false,responser:false, qrcode:require('@/pic/bi.jpg'),
      keyMaps:[
        {
          "text":"房价查询",
          "keys":['Ctrl','R']
        },
        {
          "text":"酒店指标",
          "keys":['Ctrl','P']
        },
        {
          "text":"可卖房",
          "keys":['Ctrl','D']
        },
        {
          "text":"综合预定",
          "keys":['Ctrl','Z']
        },
        {
          "text":"锁房",
          "keys":['Ctrl','B']
        }
      ]
    }
  },
  watch: {
    '$store.getters.msgcount': function () {
      this.getTraceList();
    }
  },
  mounted: function(){
    this.getTraceList();
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    getTraceList(){
      get_trace_list({'type':'GTICKET'}).then(response => {
        this.refreshMsgCount(response.data.records);
      }).catch(() => {
      })
    },
    refreshMsgCount(data){
      this.msgData = data;
      let count = 0;
      let relatedid = "";
      if(this.msgData!=null && this.msgData.length>0){
        this.msgData.forEach(row => {
          relatedid = row.relatedid;
          if(relatedid.length<=0){//发布者
            if(row.resmsg.length>0){
              count++;
            }
          }else{
            if(row.resmsg.length<=0){
              count++;
            }
          }
        });
      }
      this.msgs = count;
      this.responser = relatedid.length<=0;
    },
    handleApply(row){
      this.$prompt('请输入回复内容', '回复', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if(value==null || value.length<=0){
          this.$message({ message: '请输入回复内容', type: 'warning'});
        }else{
          apply_trace({'sqlid':row.sqlid,'content':value}).then((response) => {
            this.refreshMsgCount(response.data.records);//万一消息触发失败还可以手动刷新
          });
        }
      }).catch(() => {});
    },
    handleFinish(row){
      finish_trace({'sqlid':row.sqlid}).then((response) => {
        this.refreshMsgCount(response.data.records);//万一消息触发失败还可以手动刷新
      });
    },
    savePass(){
      this.$refs.passwordDialog.save();
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff; //
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .section-container {
    float: left;
    line-height: 50px;
    margin-left: 35%;
    font-weight: bold;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        //margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 10px;
          vertical-align: middle;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }

    .imgdiv {
      margin-right: 0px;
      margin-top: 0px;
      //padding-top: 10px;
      position: relative;
      .img-avatar {
        cursor: pointer;
        width: 28px;
        height: 28px;
        border-radius: 10px;
        vertical-align: middle;
      }
    }
  }
}

.keymap-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 160px;
}

.keymap-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.key-box {
  display: flex;
}

.key {
  background-color: #6f6f6f;
  padding: 3px;
  border-radius: 5px;
}
</style>
