<template>
    <!-- 这个是在会议列表上的资源 -->
  <div>
    <el-form :inline="true" :model="searchForm" class="search-form">
      <el-form-item label="会场">
        <el-select v-model="searchForm.site" placeholder="请选择会场" clearable filterable>
          <el-option v-for="item in meetingsites" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会议室">
        <el-select v-model="searchForm.room" placeholder="请选择会议室" clearable filterable>
          <el-option v-for="item in meetingrooms" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tabledata" style="width: 100%" max-height="400" border>
      <el-table-column v-for="(item,index) in tablehead" :key="index" :prop="item.colcode" :label="item.colname" :min-width="item.colwidth"
                       align="center" :fixed="index<=1" :show-overflow-tooltip="true">
        <template v-if="item.childcol">
          <el-table-column v-for="(subitem,subindex) in item.childcol" :key="subindex" :prop="subitem.colcode"
                           :label="subitem.colname" :min-width="subitem.colwidth" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.data[(index-2)*timesize+subindex].status!==''" :type="calcValueType(scope.row.data[(index-2)*timesize+subindex].status)"
                      disable-transitions style="cursor:pointer;" @click="handleEdit(scope.row.data[(index-2)*timesize+subindex].sqlid)">{{ scope.row.data[(index-2)*timesize+subindex].name }}</el-tag>
              <el-button v-else size="mini" :disabled="checkNewDate(scope.row.data[(index-2)*timesize+subindex].date)"
                         @click="handleNew(scope.row.data[(index-2)*timesize+subindex],scope.row.roomdesc,subitem.colname)">新增</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="searchForm.pages.currentpage" :page-size="searchForm.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="searchForm.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
    <div style="margin-top: 10px;">
      说明: <el-tag type="primary" disable-transitions>M</el-tag> - 会议预定 &nbsp;&nbsp;
      <el-tag type="warning" disable-transitions>M</el-tag> - 会议开始 &nbsp;&nbsp;
      <el-tag type="success" disable-transitions>M</el-tag> - 会议结束
    </div>
    <!-- dialog -->
    <el-dialog v-dialogDrag :visible.sync="showMeetingDetailDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <MeetingDetail ref="meetingdetail" :meetingid="meetingid" :newroom="room" :newdate="date" :newtime="time" :visible.sync="showMeetingDetailDialog" @refresh="closeMeetingDetailDialog"/>
    </el-dialog>
  </div>
</template>

<script>
    import {meeting_resoucelist} from '@/api/order/meeting';
    import {formatDate, reckonDay, dateIsExpire} from '@/utils/calcdate';
    import {select_data} from '@/api/common/custom-data';
    import {select_res} from "@/utils/select_data_res";
    import MeetingDetail from '@/views/order/meeting/meetingdetail';

    export default {
        name: "meetingResource",
        components: {
            MeetingDetail
        },
        props: {
          visible: {type: Boolean, default: true},
          startdate: {type: String, default: formatDate(new Date())},
          enddate: {type: String, default: formatDate(reckonDay(new Date(), 6))},
          needOpenMeetingDetail: {type: Boolean, default: true},
          itemlist: {
            type: Array, default: function () {
              return []
            }
          }
        },
        data: function(){
            return{
                searchForm:{
                    site:'',room:'',startdate:this.startdate,enddate:this.enddate,
                    pages:{total:0, currentpage:1, pagesize:20}
                },
                loading:false,tabledata:[],tablehead:[],timesize:1,showMeetingDetailDialog:false,meetingid:0,
                room:'',date:formatDate(new Date()),time:'',meetingrooms:[],meetingsites:[]
            }
        },
        computed:{
            searchDate:{
                get:function(){
                    return this.searchForm.startdate!=null && this.searchForm.enddate!=null?[this.searchForm.startdate,this.searchForm.enddate]:[];
                },
                set:function(dateValue){
                    this.searchForm.startdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
                    this.searchForm.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
                }
            }
        },
        watch: {
            visible: function(val){
                if(val){
                    this.refreshTable();
                }
            }
        },
        mounted: function(){
            select_data({"keyname":"meeting_room,meeting_site"}).then(response => {
                this.meetingrooms = select_res(response.data,"meeting_room");
                this.meetingsites = select_res(response.data,"meeting_site");
            });
            this.loadTable();
        },
        methods:{
            refreshTable(){
                this.searchForm.pages.currentpage = 1;
                this.loadTable();
            },
            loadTable(){
                this.loading = true;
                meeting_resoucelist(this.searchForm).then(response => {
                    this.tabledata = response.data.tableData;
                    this.tablehead = response.data.tableHead;
                    this.searchForm.pages.total = response.data.total;
                    this.timesize = response.data.timeSize;
                }).finally(() => { this.loading = false;})
            },
            handleCurrentChange(val){
                this.searchForm.pages.currentpage = val;
                this.loadTable();
            },
            calcValueType(value){
                if(value=='N'){
                    return 'primary';
                }else if(value=='A'){
                    return 'warning';
                }else if(value=='F'){
                    return 'success';
                }
                return '';
            },
            checkNewDate(date){
                return dateIsExpire(date);
            },
            handleEdit(id){
                if(id==null||id<=0){
                    this.$message({ message: '订单数据不存在,请刷新表格', type: 'warning'});
                }else{
                    this.meetingid = id;
                    this.showMeetingDetailDialog = true;
                }
            },
            handleNew(row,roomdesc,timedesc){
              if (this.needOpenMeetingDetail) {
                this.meetingid = 0;
                this.room = row.room;
                this.date = row.date;
                this.time = row.time;
                this.showMeetingDetailDialog = true;
              } else {
                let new_item = {
                  ratecode: '',
                  product: row.room,
                  productdesc: roomdesc + " - " + timedesc,
                  producttype: 'M',
                  arrdate: row.date,
                  deptdate: row.date,
                  time: row.time,
                  price: row.price,
                  num: 1, //数量默认为1
                  lfix:1 // 1为固定价格
                }
                let isExist = false;
                if (this.itemlist.length > 0) {
                  this.itemlist.forEach(item => {
                    if (new_item.arrdate == item.arrdate
                        && new_item.deptdate == item.deptdate
                        && new_item.time == item.time
                        && new_item.product == item.product) {
                      isExist = true;
                    }
                  })
                }
                if (isExist) {
                  this.$message({ message: '该场次已添加，请勿重复添加', type: 'warning'});
                  return;
                }

                this.$emit('selected', [new_item]);
                this.$emit('update:visible', false);
              }
            },
            closeMeetingDetailDialog(){
                this.loadTable();
            }
        }
    }
</script>

<style scoped>

</style>