<template>
  <div class="main-div">
    <el-form v-show="type===''" ref="form" :model="form" :inline="true" class="search-form">
      <div v-if="showAdvance" class="advance-div">
        <el-form-item label="到店日期">
          <el-date-picker v-model="form.arrival" align="right" type="date" placeholder="选择到店日期"
                          value-format="yyyy-MM-dd" :picker-options="pickerOptions"/>
        </el-form-item>
        <el-form-item label="离店日期">
          <el-date-picker v-model="form.departure" align="right" type="date" placeholder="选择离店日期"
                          value-format="yyyy-MM-dd" :picker-options="pickerOptions"/>
        </el-form-item>
        <!--
        <el-form-item label="渠道">
          <el-select v-model="form.channel" placeholder="请选择渠道" clearable>
            <el-option v-for="item in channels" :key="item.code" :label="item.code" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        -->
        <el-form-item label="渠道">
          <el-select v-model="form.subchannel" placeholder="请选择渠道" clearable filterable>
            <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态">
          <el-select v-model="form.paystatus" placeholder="请选择付款状态" clearable>
            <el-option label="已付款" value="P"/>
            <el-option label="未付款" value="N"/>
          </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="form.status" placeholder="请选择状态" multiple collapse-tags>
            <el-option label="预定" value="N"/>
            <el-option label="到店" value="A"/>
            <el-option label="完成" value="F"/>
            <el-option label="取消" value="C"/>
            <el-option label="应到未到" value="H"/>
            <el-option label="退款中" value="P"/>
          </el-select>
        </el-form-item>
        <el-form-item label="含子预定">
          <el-select v-model="form.subres" placeholder="请选择包含的子预定" clearable multiple collapse-tags>
            <el-option label="客房" value="R"/>
            <el-option label="票务" value="T"/>
            <el-option label="餐饮" value="C"/>
            <el-option label="会议" value="M"/>
            <el-option label="SPA" value="S"/>
            <el-option label="车船" value="B"/>
            <el-option label="导游" value="G"/>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型">
          <el-select v-model="form.ordertype" placeholder="请选择订单类型" multiple collapse-tags clearable>
            <el-option label="直连" value="D"/>
            <el-option label="非直连" value="ND"/>
            <el-option label="套餐" value="P"/>
            <el-option label="非套餐" value="NP"/>
          </el-select>
        </el-form-item>
        <el-form-item label="套餐">
          <el-select v-model="form.kitcode" placeholder="请选择套餐" clearable filterable>
            <el-option v-for="item in productkits" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.nosendpms">仅PMS号为空</el-checkbox>
        </el-form-item>
      </div>
      <el-form-item label="在店日期">
        <el-date-picker v-model="form.inhouse" align="right" type="date" placeholder="选择在店日期"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions" style="width: 150px"/>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input ref="colnoinput" v-model="form.colno"
                  placeholder="CRS/OTA/线下订单号" clearable @keyup.enter.native="refreshTable"/>
      </el-form-item>
      <el-form-item label="姓名/电话">
        <el-input v-model="form.name" clearable @keyup.enter.native="refreshTable"/>
      </el-form-item>
      <el-form-item label="历史">
        <el-switch v-model="form.history" active-color="#13ce66" inactive-color="#ff4949" @change="changeHistory"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
        <el-button :icon="showAdvance?'el-icon-caret-top':'el-icon-caret-bottom'" @click="showAdvance=!showAdvance">{{ showAdvance?'收起':'更多' }}</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" icon="el-icon-plus" :disabled="form.history" @click="handleNew">新增</el-button>
        <el-dropdown style="margin-left: 10px" @command="handleCommand">
          <el-button type="primary" :loading="buttonLoading">重发<i class="el-icon-arrow-down el-icon--right"/></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="PMS" :disabled="form.history">PMS</el-dropdown-item>
            <el-dropdown-item command="CRM">CRM</el-dropdown-item>
            <el-dropdown-item command="STATUS">OTA</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table v-loading="loading" :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
              :max-height="tableHeight" border @sort-change="handleSortChange" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='C'? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="subres" label="子预定" min-width="140">
        <template slot-scope="subresscope">
          <!-- 这里改成有了才显示 不然太多了
          <el-badge value="房" class="item" :type="subresscope.row.hasroom==1?'success':'info'"/>
          <el-badge value="票" class="item" :type="subresscope.row.hasticket==1?'success':'info'"/>
          <el-badge value="餐" class="item" :type="subresscope.row.hascatering==1?'success':'info'"/>
          <el-badge value="会" class="item" :type="subresscope.row.hasmeeting==1?'success':'info'"/>
          <el-badge value="自" class="item" :type="subresscope.row.hasentertainment==1?'success':'info'"/>
          -->
          <el-badge v-if="subresscope.row.hasroom" value="房" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasticket" value="票" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hascatering" value="餐" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasmeeting" value="会" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasspa" value="娱" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasboat" value="船" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasguide" value="导" class="item" type="primary"/>
          <el-badge v-if="subresscope.row.hasentertainment" value="自" class="item" type="primary"/>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="总价" min-width="80">
        <template slot-scope="totalscope">
          <el-popover trigger="hover" placement="top">
            <p>客房价格: {{ totalscope.row.roomprice }}</p>
            <p>票务价格: {{ totalscope.row.ticketprice }}</p>
            <p>餐饮价格: {{ totalscope.row.cateringprice }}</p>
            <p>会议价格: {{ totalscope.row.meetingprice }}</p>
            <p>SPA价格: {{ totalscope.row.spaprice }}</p>
            <p>车船价格: {{ totalscope.row.boatprice }}</p>
            <p>导游价格: {{ totalscope.row.guideprice }}</p>
            <p>自定义项目价格: {{ totalscope.row.enterprice }}</p>
            <div slot="reference">{{ (totalscope.row.roomprice+totalscope.row.ticketprice+
              totalscope.row.cateringprice+totalscope.row.meetingprice+totalscope.row.enterprice+
              totalscope.row.spaprice+totalscope.row.boatprice+totalscope.row.guideprice).toFixed(2) }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="deposit" label="付款" min-width="80" :formatter="depositFormatter"/>
      <el-table-column prop="colno" label="综合订单号" min-width="170"/>
      <el-table-column prop="name" label="姓名" min-width="120" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="telephone" label="电话" min-width="130"/>
      <el-table-column prop="arrdate" label="到店" min-width="100" sortable="custom"/>
      <el-table-column prop="enddate" label="离店" min-width="100" sortable="custom"/>
      <!--
      <el-table-column prop="channel" label="渠道" min-width="120" sortable="custom"/>
      -->
      <el-table-column prop="channelsource" label="渠道" min-width="75" sortable="custom"/>
      <el-table-column prop="packagecode" label="套餐" min-width="110" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="packagenum" label="份数" min-width="60" :formatter="packageNumFormatter"/>
      <!--
      <el-table-column prop="packagecode" label="套餐" min-width="100"/>
      -->
      <el-table-column prop="channelno" label="OTA订单号" min-width="230" sortable="custom"/>
      <el-table-column prop="pmscolno" label="线下订单号" min-width="230" sortable="custom"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="160">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="handleEdit(operatescope.row)">编辑</el-button>
          <el-button size="mini" type="danger" :disabled="operatescope.row.status==='C'||operatescope.row.status==='F'||operatescope.row.status==='P'||form.history"
                     @click="handleCancel(operatescope.row)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="sizes,total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;"
                   background @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
    <!-- Dialog -->
    <el-dialog v-dialogDrag :visible.sync="showColDetailDialog" :close-on-click-modal="false" append-to-body width="80%" top="6vh" @closed="closeColDetailDialog">
      <ColDetail ref="coldetail" :colid="colid" :history="form.history" :isgroup="isgroup" :visible.sync="showColDetailDialog"/>
    </el-dialog>

    <el-dialog v-dialogDrag :visible.sync="showCrmDialog" width="40%" :close-on-click-modal="false" append-to-body title="CRM订单上传">
      <el-form :model="crmData" label-width="auto">
        <el-form-item>
          <el-radio v-model="crmData.type" label="1">表格选择项</el-radio>
          <el-radio v-model="crmData.type" label="2">自定义条件</el-radio>
        </el-form-item>
        <el-form-item v-show="crmData.type=='2'" label="到店日期">
          <el-date-picker v-model="crmArrSelect" type="daterange" align="right" unlink-panels
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item v-show="crmData.type=='2'" label="离店日期">
          <el-date-picker v-model="crmDepSelect" type="daterange" align="right" unlink-panels
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item v-show="crmData.type=='2'" label="创建日期">
          <el-date-picker v-model="crmCreateSelect" type="daterange" align="right" unlink-panels
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleResendCrm">上传</el-button>
        <el-button @click="showCrmDialog=false">退出</el-button>
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag :visible.sync="showCancelDialog" width="40%" :close-on-click-modal="false" append-to-body title="取消综合预定">
      <el-form :model="cancelForm" label-width="auto">
        <el-form-item label="取消理由">
          <el-input v-model="cancelForm.cancelReason" clearable/>
        </el-form-item>
        <el-form-item label="系统取消规则">
          <el-switch v-model="cancelForm.calcRefund" active-color="#13ce66" inactive-color="#ff4949"/>
        </el-form-item>
        <el-form-item v-show="!cancelForm.calcRefund" label="扣款百分比">
          <el-input-number v-model="cancelForm.percent" :min="0"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="buttonLoading" @click="doCancel">确定</el-button>
        <el-button @click="showCancelDialog=false">退出</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {col_list, cancel_col, resend_col, resend_crm, resend_status} from '@/api/order/col';
import {select_data} from '@/api/common/custom-data';
import {select_res} from "@/utils/select_data_res";
import {formatDate} from '@/utils/calcdate';
import {getColStatus} from '@/utils/status-format';
import ColDetail from '@/views/order/col/coldetail';

export default {
  name:'order_col',
  components: {
    ColDetail
  },
  props: {
    type:{ type: String,default: ""},
    isgroup:{ type: Boolean,default: false}
  },
  data() {
    return {
      form:{
        inhouse:formatDate(new Date()), colno:'', status:['N','A','F'], subres:[], name:'',
        arrival:'', departure:'', paystatus:'', subchannel:'', history:false, isgroup:this.isgroup, lPack:false,
        pages:{total:0, currentpage:1, pagesize:20, sortname:'enddate,name', sortorder:'ascending'}
      },
      pickerOptions: {
        shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
      },
      crmData:{
        type:'1',arrfrom:'',arrto:'',depfrom:'',depto:'',createfrom:'',createto:'',colnos:[]
        //
      },
      subchannels:[],channels:[],productkits:[],
      tableData: [],multipleSelection:[],tableHeight:500,
      loading:false, showAdvance:false, showColDetailDialog:false, colid:0,
      buttonLoading:false,showCrmDialog:false,showCancelDialog:false,
      cancelForm:{
        sqlid:0,cancelReason:'',calcRefund:false,percent:0
      }
    }
  },
  computed:{
    crmArrSelect:{
      get:function(){
        return this.crmData.arrfrom!=null && this.crmData.arrto!=null?[this.crmData.arrfrom,this.crmData.arrto]:[];
      },
      set:function(dateValue){
        this.crmData.arrfrom = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.crmData.arrto = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    },
    crmDepSelect:{
      get:function(){
        return this.crmData.depfrom!=null && this.crmData.depto!=null?[this.crmData.depfrom,this.crmData.depto]:[];
      },
      set:function(dateValue){
        this.crmData.depfrom = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.crmData.depto = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    },
    crmCreateSelect:{
      get:function(){
        return this.crmData.createfrom!=null && this.crmData.createto!=null?[this.crmData.createfrom,this.crmData.createto]:[];
      },
      set:function(dateValue){
        this.crmData.createfrom = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.crmData.createto = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    }
  },
  mounted: function(){
    this.$refs.colnoinput.focus();
    select_data({'keyname':'subchannel,productkit'}).then(response => {
      this.subchannels = select_res(response.data,"subchannel");
      this.productkits = select_res(response.data,"productkit");
      // this.channels = select_res(response.data,"channel");
    });
    this.loadTable();
  },
  methods: {
    statusFormatter(val){
      return getColStatus(val);
    },
    depositFormatter(row){
      return row.deposit.toFixed(2);
    },
    // packageFormatter(row){
    //   if(row.packagecode.length>0){
    //     return row.packagecode+'-'+row.packagenum+'份';
    //   }
    //   return '';
    // },
    packageNumFormatter(row){
      if(row.packagenum>0){
        return row.packagenum;
      }
      return '';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    refreshTable(){
      this.form.pages.currentpage = 1;
      this.loadTable();
    },
    changeHistory(){
      if(this.form.history){
        // this.form.status = ['F'];
        this.form.inhouse = '';
      }else{
        // this.form.status = ['N','A'];
        this.form.inhouse = formatDate(new Date());
      }
      this.refreshTable();
    },
    loadTable(){
      if(this.type=='INDEX'){
        this.form.inhouse = '';
        this.form.createdate = formatDate(new Date());
        // this.form.status = ['N','A','F'];
        this.tableHeight = 450;
      }
      this.loading = true;
      col_list(this.form).then(response => {
          this.tableData = response.data.records;
          this.form.pages.total = response.data.total;
        }).finally(() => { this.loading = false;})
    },
    tableRowClassName({row}){
      if(row.status==='C'){
        return 'cancel-row';
      }else if(row.status==='A'){
        return 'start-row';
      }else if(row.status==='F'){
        return 'finish-row';
      }
      return null;
    },
    handleCurrentChange(val){
      this.form.pages.currentpage = val;
      this.loadTable();
    },
    handleSizeChange(val){
      this.form.pages.pagesize = val;
      this.refreshTable();
    },
    handleSortChange(column){
      this.form.pages.sortname = column.prop;
      this.form.pages.sortorder = column.order;
      this.loadTable();
    },
    handleCommand(command) {
      if(command=='PMS'){
        this.handleResendPms();
      }else if(command=='CRM'){
        this.showCrmDialog = true;
      }else{
        this.handleResendStatus();
      }
    },
    handleNew() {
      this.colid = 0;
      this.showColDetailDialog = true;
    },
    handleEdit(row) {
      this.colid = row.sqlid;
      this.showColDetailDialog = true;
    },
    handleCancel(row) {
      this.cancelForm.sqlid = row.sqlid;
      this.showCancelDialog = true;
    },
    doCancel(){
      this.buttonLoading = true;
      cancel_col(this.cancelForm).then(() => {
        this.showCancelDialog = false;
        this.$message({ message: '取消成功!', type: 'success'});
        this.refreshTable();
      }).finally(()=>{
        this.buttonLoading = false;
      })
    },
    handleResendPms() {
      if(this.multipleSelection.length<=0){
        this.$message({ message: '至少选择一条记录!', type: 'warning'});
      }else{
        this.$confirm('确定重新下发PMS?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          let selectIds = [];
          this.multipleSelection.forEach(row=>{
            selectIds.push(row.sqlid);
          });
          this.buttonLoading = true;
          resend_col({"ids":selectIds}).then((response) => {
            this.$msgbox({ title: '消息', message: response.data, confirmButtonText: '关闭'}).catch(() => {})
          }).finally(()=>{
            this.buttonLoading = false;
          });
        }).catch(() => {});
      }
    },
    handleResendCrm() {
      let canSend = true;
      let selectColnos = [];
      if(this.crmData.type=='1'){
        if(this.multipleSelection.length<=0){
          canSend = false;
          this.showCrmDialog = false;
          this.$message({ message: '至少选择一条记录!', type: 'warning'});
        }else{
          this.multipleSelection.forEach(row=>{
            selectColnos.push(row.colno);
          });
        }
      }else{
        if((this.crmData.arrfrom==null||this.crmData.arrfrom.length<=0||
                this.crmData.arrto==null||this.crmData.arrto.length<=0)&&
                (this.crmData.depfrom==null||this.crmData.depfrom.length<=0||
                this.crmData.depto==null||this.crmData.depto.length<=0)&&
                (this.crmData.createfrom==null||this.crmData.createfrom.length<=0||
                this.crmData.createto==null||this.crmData.createto.length<=0)){
          canSend = false;
          this.$message({ message: '搜索条件不能为空!', type: 'warning'});
        }
      }
      this.crmData.colnos = selectColnos;
      if(canSend){
        // this.$confirm('确定重新下发CRM?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          resend_crm(this.crmData).then((response) => {
            this.$msgbox({ title: '消息', message: response.data, confirmButtonText: '关闭'}).catch(() => {})
          }).finally(()=>{
            this.buttonLoading = false;
          });
        // }).catch(() => {});
        this.showCrmDialog = false;
      }
    },
    handleResendStatus() {
      if(this.multipleSelection.length<=0){
        this.$message({ message: '至少选择一条记录!', type: 'warning'});
      }else{
        this.$confirm('确定重新上传OTA?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          let selectColnos = [];
          this.multipleSelection.forEach(row=>{
            selectColnos.push(row.colno);
          });
          this.buttonLoading = true;
          resend_status({"colnos":selectColnos}).then((response) => {
            this.$msgbox({ title: '消息', message: response.data, confirmButtonText: '关闭'}).catch(() => {})
          }).finally(()=>{
            this.buttonLoading = false;
          });
        }).catch(() => {});
      }
    },
    closeColDetailDialog(){
      if(!this.form.history){
        this.loadTable();//这里不用跳到第一页
      }
    }
  }
}
</script>

<style>
  .el-table .cancel-row {
    background: #FFEBEE;
  }
  .el-table .finish-row {
    background: #E8F5E9;
  }
  .el-table .start-row {
    background: #E3F2FD;
  }
</style>
